import { useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';

import { Header } from '../navigation/header';
import { getVerestroIbanAPI, getVerestroIbanBalanceAPI } from '../../services/ibans';
import useIsMobile from '../../hooks/useIsMobile';
import { PrimaryButton } from '../buttons/primary-button';
import { CustomModal } from '../utils/modal';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import { getIbanTransactions } from '../../services/transactions';
import TransactionItem from './transactionItem';

export default function IbanTransactions() {
	const navigate = useNavigate();

	const token = localStorage.getItem('token');
	const decodedToken = decodeToken(token);
	const fullname = decodedToken.firstName + ' ' + decodedToken.lastName;

	const [isLoading, setIsLoading] = useState(false);

	const [ibanData, setIbanData] = useState(null);

	const isMobile = useIsMobile();

	const [ibanBalance, setIbanBalance] = useState(0);

	const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);

	const [transactions, setTransactions] = useState([]);

	const getVerestroIBANDetails = async () => {
		// add error when not agreed
		if (!isLoading) {
			setIsLoading(true);
			const response = await getVerestroIbanAPI();
			if (!response.isSuccess) {
				return;
			}
			setIbanData(response.data);
			const ibanBalanceResponse = await getVerestroIbanBalanceAPI();

			if (ibanBalanceResponse.isSuccess) setIbanBalance(ibanBalanceResponse.data);
		}
		setIsLoading(false);
	};

	const fetchTransactions = async () => {
		const transactions = await getIbanTransactions();

		if (transactions.isSuccess) setTransactions(transactions.data);
	};

	useEffect(() => {
		fetchTransactions();
		getVerestroIBANDetails();
	}, []);

	return (
		<div>
			<Header />
			{!ibanData ? (
				<p className="text-center mt-5">We are still processing your IBAN request it can take a while</p>
			) : (
				<div>
					<p className="text-center text-red-500 font-bolder">
						Please be advised you can send money to your account only as following
						<ul>
							<li>* Your employer</li>
							<li>* Your family</li>
							<li>* Account own by you</li>
						</ul>
					</p>

					<div className="flex justify-center mb-5 mt-5">
						<PrimaryButton text="Deposit Options" onClick={() => setIsOptionModalOpen(true)} className="p-5" />
						<PrimaryButton text="Send / Receive" onClick={() => navigate('/transactions/iban/actions')} className="p-5 ms-5" />
					</div>

					<h1 className="text-center text-xl">Your IBAN EUR Account</h1>
					<div className="flex flex-col items-center mt-5">
						<div>
							<div className="flex flex-col justify-center items-center ms-5 mb-2 font-bolder">
								<img alt="bank_icon" src="/assets/bank_icon.svg" className="w-15 h-10 me-3"></img>
								IBAN Account Balance
							</div>
							<div className="bg-brown rounded-3xl p-2 text-center font-bolder text-white mb-5">€{numeral(ibanBalance).format('0,0.00')}</div>
						</div>
					</div>
					{isMobile ? (
						<div>
							<p className="font-bolder mb-2 text-xl text-center">YOUR IBAN ACCOUNT DETAILS</p>
							<div className="flex justify-center items-center p-10 font-bold">
								<table className="table-auto border-collapse border border-gray-400">
									<tbody>
										<tr>
											<td className="border px-4 py-2">Account Holder Name</td>
											<td className="border px-4 py-2">{fullname}</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">IBAN</td>
											<td className="border px-4 py-2">{ibanData ? ibanData.iban : ''}</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">BIC / Swift</td>
											<td className="border px-4 py-2">{ibanData ? ibanData.bic : ''}</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">Bank Name</td>
											<td className="border px-4 py-2">Bank Polska Kasa Opieki Spolka Akcyjna</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">Address</td>
											<td className="border px-4 py-2">al. Wojska Polskiego 1</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">City</td>
											<td className="border px-4 py-2">Szczecin</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">ZIP</td>
											<td className="border px-4 py-2">70-470</td>
										</tr>
										<tr>
											<td className="border px-4 py-2">Country</td>
											<td className="border px-4 py-2">Poland (PL)</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					) : (
						<div className="flex flex-col justify-center items-center p-10 font-bold">
							<p className="font-bolder mb-2 text-xl">YOUR IBAN ACCOUNT DETAILS</p>
							<div>
								<table className="table-auto border-collapse border border-gray-400 w-full">
									<thead>
										<tr>
											<th className="border px-4 py-2">Account Holder Name</th>
											<th className="border px-4 py-2">IBAN</th>
											<th className="border px-4 py-2">BIC / SWIFT</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="border px-4 py-2">{fullname}</td>
											<td className="border px-4 py-2">{ibanData ? ibanData.iban : ''}</td>
											<td className="border px-4 py-2">{ibanData ? ibanData.bic : ''}</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="p-2">
								<table className="table-auto border-collapse border border-gray-400 w-full">
									<thead>
										<tr>
											<th className="border px-4 py-2">Bank Name</th>
											<th className="border px-4 py-2">Address</th>
											<th className="border px-4 py-2">City</th>
											<th className="border px-4 py-2">ZIP</th>
											<th className="border px-4 py-2">Country</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="border px-4 py-2">Bank Polska Kasa Opieki Spolka Akcyjna</td>
											<td className="border px-4 py-2">al. Wojska Polskiego 1</td>
											<td className="border px-4 py-2">Szczecin</td>
											<td className="border px-4 py-2">70-470</td>
											<td className="border px-4 py-2">Poland (PL)</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					)}

					{transactions.length > 0 ? (
						transactions.map((transaction) => <TransactionItem transaction={transaction} />)
					) : (
						<p className="text-center mt-10">You don't have any transactions yet</p>
					)}
					<CustomModal
						isOpen={isOptionModalOpen}
						onClose={() => setIsOptionModalOpen(false)}
						title={'You have several options to deposit to your IBAN account'}
						children={
							<div className="text-center p-2">
								<p>
									<b className="text-red-500">Cash</b> - Any money transfer institutie (Exchange) up to 15,000 EUR monthly
								</p>
								<p>
									<b className="text-red-500">Paytora Digital Account</b> - Up to 5,000 EUR per transaction <br />
									(Up to 10,000 EUR daily)
								</p>

								<p>
									<b className="text-red-500">Wire Transfer</b> - No Limit
								</p>

								<div className="flex justify-center">
									<PrimaryButton
										onClick={() => {
											setIsOptionModalOpen(false);
										}}
										text="Close"
										className="p-5 mt-5"
									/>
								</div>
							</div>
						}
					/>
				</div>
			)}
		</div>
	);
}
