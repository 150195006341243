import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../components/buttons/primary-button';
import CardPaymentForm from '../../components/inputs/card-input';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/text-input';
import SelectInput from '../../components/inputs/select-input';
import { useNavigate } from 'react-router-dom';
import { getUserCardsAPI } from '../../services/cards';
import Loader from '../utils/loader';
import { cardToCardTransfer, cardToCardVerify } from '../../services/card-to-card';
import Stepper from '../../components/steppers/stepper';
import { getFeeByType } from '../../services/fees';

const steps = ['Choose Card To Transfer', 'Choose Payment & Amount'];

export default function CardToCard() {
	const navigate = useNavigate();

	const [isNextPage, setIsNextPage] = useState(false);

	const [amount, setAmount] = useState();

	const [cards, setCards] = useState([]);

	const [selectedCard, setSelectedCard] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const [receiverCardVerestroId, setReceiverCardVerestroId] = useState(null);

	const [totalFee, setTotalFee] = useState(null);
	const [pTotalFee, setPtotalFee] = useState(null);

	const [errMsg, setErrMsg] = useState('');

	const submitCardDataHandler = async (card) => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await cardToCardVerify({ receiverCardNumber: card.number, receiverCardCVV: card.cvc, customerNumber: card.customerNumber });

			if (!response.isSuccess) {
				setErrMsg(response.error);
				setIsLoading(false);
				return;
			}
			setReceiverCardVerestroId(response.data);
		}
		setIsLoading(false);
		setIsNextPage(true);
	};

	const transferHandler = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await cardToCardTransfer({
				amount,
				currency: 'EUR',
				senderCardId: selectedCard,
				receiverVerestroCardId: receiverCardVerestroId,
			});
			if (!response.isSuccess) {
				setErrMsg(response.error);
				setIsLoading(false);
				setSelectedCard(cards[0]._id);
				return;
			}
		}
		setIsLoading(true);
		navigate('/success');
	};

	const getCalculatedFee = () => {
		const percentageFee = (parseInt(amount) * pTotalFee) / 100;

		return percentageFee > totalFee ? percentageFee : totalFee;
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			const availableCards = response.cards.filter((card) => card.status === 'A');
			setCards(availableCards);
			setSelectedCard(availableCards[0]._id);
			setIsLoading(false);
			const feesResponse = await getFeeByType({ type: 'card2card' });
			if (feesResponse.isSuccess) {
				feesResponse.data.forEach((fee) => {
					if (fee.kind === 'percentage') setPtotalFee(fee.cost);
					else setTotalFee(fee.cost);
				});
			}
		};

		fetchData();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header
				backbuttonNavigation={() => {
					if (isNextPage) setIsNextPage(false);
					else {
						navigate('/');
					}
				}}
			/>
			<h1 className="text-xl text-center mb-5">Card To Card Transfer</h1>
			{cards.length > 0 && <Stepper steps={steps} currentStepIndex={isNextPage ? 1 : 0} />}
			{cards.length === 0 ? (
				<div className="text-center">
					You need to apply to cards before using this method. <br />
					<a className="font-bolder text-primary hover:text-secondary" href="/cards">
						Apply for a new card
					</a>
				</div>
			) : !isNextPage ? (
				<div className="flex flex-col items-center ">
					<CardPaymentForm submitHandler={submitCardDataHandler} showCustomerNumber={true} />
					<p className="text-center text-red-500">{errMsg}</p>
				</div>
			) : (
				<div className="flex flex-col items-center p-5">
					<label className="font-bolder" htmlFor="amount">
						Select Amount To Transfer
					</label>
					<div className="flex justify-center">
						<Input
							name="amount"
							placeholder="Transfer Amount"
							type="number"
							value={amount}
							onChange={(value) => setAmount(value)}
							className={`bg-secondary border-2 border-black inline-block`}
							inputMode="numeric"
							pattern="[0-9]"
						/>
						<h1 className="mt-2 text-xl ms-2">€</h1>
					</div>

					<label className="font-bolder" htmlFor="cards">
						Select Card To Transfer From
					</label>
					<SelectInput
						name="cards"
						onChange={setSelectedCard}
						options={cards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
						defaultValue={{ value: cards[0]._id, label: cards[0].pan + ' - ' + cards[0].balance + '€' }}
						className=""
					/>
					<div className="text-center mb-4 font-bolder">
						{totalFee && pTotalFee && amount > 0 && (
							<p className="mb-1 mt-1 text-red-500">
								{getCalculatedFee() + '€ processing fee'} <br />
								{parseInt(amount) - getCalculatedFee() + '€ '}
								will be transfered to the selected card
							</p>
						)}
					</div>
					<p className="text-red-500 mb-5 font-bolder">{errMsg}</p>
					<PrimaryButton text="Transfer" onClick={transferHandler} className="p-10 mb-5" />
				</div>
			)}
		</div>
	);
}
