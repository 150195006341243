import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './i18n';

import { getStorageToken, handleTokenExpiration } from './utils/functions/token';

import Login from './pages/auth/login';
import Register from './pages/auth/register.jsx';

import Dashboard from './pages/dashboard/dashboard';

import Messages from './pages/messages/messages';
import TransferRequest from './pages/transfer-request/transfer-request';
import TopUpOtherSelectAmount from './pages/top-up/other/other-topup-select-amount';
import SwapCurrency from './pages/swap/swap-currency';
import MemberTransfer from './pages/transfer-request/member-transfer.jsx';

import NotFound404 from './pages/utils/404';
import ResultPage from './pages/utils/result-page';
import SuspendedPage from './pages/utils/suspended';

import UserProfile from './pages/auth/userProfile.jsx';
import ForgotPassword from './pages/auth/forgotPassword.jsx';

import TermsAndConditions from './pages/terms/terms-and-conditions';
import PrivacyPolicy from './pages/terms/privacy-policy';
import CardTermsAndConditions from './pages/terms/card-terms-and-conditions.jsx';
import CardPrivacyPolicy from './pages/terms/card-privacy-policy.jsx';
import Cards from './pages/cards/cards.jsx';
import TopUp from './pages/top-up/top-up.jsx';
import KYCProcess from './pages/kyc/kyc_process.jsx';
import CardToCard from './pages/cards/card-to-card.jsx';
import IbanTermsAndConditions from './pages/terms/iban-terms-and-conditions.jsx';

// import IbanTransactions from './components/transactions/iban-transactions.jsx';
// import IbanTermsAndConditions from './pages/terms/iban-terms-and-conditions.jsx';

import PayToraTransactions from './components/transactions/paytoraTransactions.jsx';
import CardTransactions from './components/transactions/cardsTransactions.jsx';

import IbanTransactions from './components/transactions/iban-transactions.jsx';
import BankWithdraw from './pages/transfer-request/bank-withdraw.jsx';
import IbanActions from './components/transactions/iban-actions.jsx';
import IbanMemberTransfer from './pages/transfer-request/iban-member-transfer.jsx';
import IbanCardTransfer from './pages/transfer-request/iban-card-transfer.jsx';
import IbanTransfer from './pages/transfer-request/iban-transfer.jsx';
// import MerchantTransfer from './pages/transfer-request/merchant-transfer.jsx';

const AuthenticatedRoute = ({ element, kycRequired = false, ibanRequired = false }) => {
	const token = getStorageToken();
	const kycValue = localStorage.getItem('kyc') ?? 'none';
	const suspended = JSON.parse(localStorage.getItem('isSuspended'));
	const hasIban = JSON.parse(localStorage.getItem('hasIban'));

	useEffect(() => {
		if (token) {
			handleTokenExpiration(token);
		}
	}, [token]);

	if (!token) {
		return <Login />;
	}

	if (suspended) {
		return <SuspendedPage />;
	}

	if (kycRequired && kycValue !== 'completed') {
		// return <KYC />;
		return <KYCProcess />;
	}

	if (ibanRequired && !hasIban) {
		return <IbanTransactions />;
	}

	return element;
};

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/terms" element={<TermsAndConditions />}></Route>
				<Route path="/privacy" element={<PrivacyPolicy />}></Route>
				<Route path="/success" element={<ResultPage isSuccess={true} />}></Route>
				<Route path="/failed" element={<ResultPage />}></Route>
				<Route path="/404" element={<NotFound404 />} />
				<Route path="*" element={<Navigate to="/404" />} />

				{/* Authenticated Routes */}
				<Route path="/" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />
				<Route path="/profile" element={<AuthenticatedRoute element={<UserProfile />} kycRequired={true} />} />
				<Route path="/kyc" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />
				<Route path="/swap-currency" element={<AuthenticatedRoute element={<SwapCurrency />} kycRequired={true} />} />
				<Route path="/cards" element={<AuthenticatedRoute element={<Cards />} kycRequired={true} />} />

				<Route path="/top-up" element={<AuthenticatedRoute element={<TopUp />} kycRequired={true} />} />

				<Route path="/top-up/other/select/:currencyCode?" element={<AuthenticatedRoute element={<TopUpOtherSelectAmount />} kycRequired={true} />} />

				<Route path="/messages" element={<AuthenticatedRoute element={<Messages />} kycRequired={true} />} />

				<Route path="/transfer-request" element={<AuthenticatedRoute element={<TransferRequest />} kycRequired={true} />} />
				<Route path="/transfer-request/member/:currencyCode?" element={<AuthenticatedRoute element={<MemberTransfer />} kycRequired={true} />} />

				<Route path="/transfer-request/iban" element={<AuthenticatedRoute element={<IbanTransfer />} kycRequired={true} />} />

				<Route path="/card-to-card" element={<AuthenticatedRoute element={<CardToCard />} kycRequired={true} />} />

				<Route path="/card/terms" element={<AuthenticatedRoute element={<CardTermsAndConditions />} kycRequired={true} />} />
				<Route path="/card/privacy" element={<AuthenticatedRoute element={<CardPrivacyPolicy />} kycRequired={true} />} />

				<Route path="/transactions/paytora/:currency?" element={<AuthenticatedRoute element={<PayToraTransactions />} kycRequired={true} />} />

				<Route path="/transactions/cards" element={<AuthenticatedRoute element={<CardTransactions />} kycRequired={true} />} />

				<Route path="/transactions/iban" element={<AuthenticatedRoute element={<IbanTransactions />} kycRequired={true} />} />

				<Route path="/transactions/iban/actions" element={<AuthenticatedRoute element={<IbanActions />} kycRequired={true} />} />

				<Route
					path="/iban/transfer/member"
					element={<AuthenticatedRoute element={<IbanMemberTransfer />} kycRequired={true} ibanRequired={true} />}
				/>
				{/* <Route path="/iban/transfer/wire" element={<AuthenticatedRoute element={<BankWithdraw />} kycRequired={true} ibanRequired={true} />} /> */}

				<Route path="/iban/transfer/card" element={<AuthenticatedRoute element={<IbanCardTransfer />} kycRequired={true} ibanRequired={true} />} />

				<Route path="/iban/terms" element={<IbanTermsAndConditions />}></Route>
				{/* 
				<Route path="/bank-withdraw/select-type" element={<AuthenticatedRoute element={<SelectBankWithdrawType />} kycRequired={true} />} />  */}

				{/* Not In Use Routes */}

				{/* <Route path="/top-up/other" element={<AuthenticatedRoute element={<TopUpOtherPreview />} kycRequired={true} />} /> */}
				{/* <Route path="/login" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} /> */}
				{/* <Route path="/merchant/payment/:merchantId" element={<MerchantPaymentHome />}></Route> */}
				{/* <Route path="/top-up/wire-transfer" element={<AuthenticatedRoute element={<TopUpWrire />} kycRequired={true} />} /> */}
				{/* <Route path="/admin-message" element={<AuthenticatedRoute element={<AdminMessage />} kycRequired={true} />} /> */}
				{/* <Route path="/transfer-request/merchant" element={<AuthenticatedRoute element={<MerchantTransfer />} kycRequired={true} />} /> */}
				{/* 
				
				*/}
			</Routes>
		</Router>
	);
}

export default App;
