import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../utils/loader';
import { TranferSchema } from '../../utils/validations/user-schema';

import { getFeeByType } from '../../services/fees';
import { transferToIban } from '../../services/transactions';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/text-input';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import useValidation from '../../hooks/useValidation';
import { useSelector } from 'react-redux';

export default function IbanTransfer() {
	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const [amount, setAmount] = useState();

	const [errorMsg, setErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [totalFee, setTotalFee] = useState(0);
	const [totalPfee, setTotalPfee] = useState(0);

	const { validationErrMsg, validate } = useValidation(TranferSchema);

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'transfer_to_iban' });
			if (feesResponse.isSuccess) {
				feesResponse.data.forEach((fee) => {
					if (fee.kind === 'percentage') {
						setTotalPfee(fee.cost);
					} else setTotalFee(fee.cost);
				});
			}
		};

		fetchData();
	}, []);

	const transfer = async () => {
		if (!amount || amount <= 0) return setErrorMsg('Please enter a valid amount');

		if (!isLoading) {
			const response = await transferToIban(amount);
			if (!response.isSuccess) {
				setErrorMsg(response.error);
				setIsLoading(false);
				return;
			}
			setIsLoading(true);
			navigate('/');
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header />
			<div className="flex flex-col items-center pb-20">
				<h1 className="font-bolder text-3xl xsm:text-[2rem] mb-5 text-center">Transfer To Your IBAN Account</h1>

				<div className="flex justify-center">
					<div>
						<Input
							disabled={true}
							value="EUR"
							className={`bg-secondary border-2 border-black inline-block ${totalFee > 0 && amount > 0 ? 'mb-0' : 'mb-10'}`}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<p className="text-center mb-2">
							<b>Your available amount is: </b>
							{(() => {
								const wallet = userWallets.find((wallet) => wallet.currency === 'EUR');
								return wallet ? `${wallet.balance}${wallet.symbol}` : '0.00';
							})()}
						</p>

						<label className="flex justify-center">Transfer Amount</label>

						<Input
							placeholder="Transfer Amount"
							type="number"
							value={amount}
							onChange={(value) => setAmount(value)}
							className={`bg-secondary border-2 border-black inline-block ${totalFee > 0 && amount > 0 ? 'mb-0' : 'mb-10'}`}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="text-center mb-4 font-bolder">
							{totalFee > 0 && totalPfee > 0 && amount > 0 && (
								<p className="mb-1 mt-1 text-red-500">{parseInt(amount) - (totalPfee / 100) * parseInt(amount) - totalFee} will be transfered</p>
							)}
							<p className="text-red-500">
								Transaction fee will cost {totalPfee}% + {totalFee}€
							</p>
						</div>
					</div>
				</div>
				<p className="text-red-500 font-bolder">{errorMsg}</p>
				{validationErrMsg && <p className="text-red-500 font-bolder">{validationErrMsg}</p>}
				{errorMsg && <p className="text-red-500 font-bolder">{errorMsg}</p>}

				<SecondaryButton text="Send" className="p-5" onClick={transfer} />
			</div>
		</div>
	);
}
