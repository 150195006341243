import { apiRequest } from './api';

export async function transferToMerchantAPI(data) {
	try {
		await apiRequest('/user/transfer/merchant', 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function transferToUserAPI(data) {
	try {
		await apiRequest('/user/transfer/user', 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export const transferToIban = async (amount) => {
	try {
		await apiRequest('/user/transfer/iban', 'POST', { amount });

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export async function otherTopUpAccountAPI(data) {
	try {
		const response = await apiRequest('/user/top-up', 'POST', data);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function exportTransactions(timeframe) {
	try {
		const response = await apiRequest('/user/transactions/export', 'POST', { timeframe });
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getTransactionsByType(currency) {
	try {
		const response = await apiRequest('/user/transactions/paytora/' + currency, 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getCardTransactions(cardId) {
	try {
		const response = await apiRequest('/user/transactions/card/' + cardId, 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getIbanTransactions() {
	try {
		const response = await apiRequest('/user/transactions/iban', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
