import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/navigation/header';
import { useState } from 'react';
import { CustomModal } from '../../components/utils/modal';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { PincodeModal } from '../../components/modals/pincodeModal';
import { setUserPincode, verifyUserPincode } from '../../services/pincode';

export default function TransferRequest() {
	const navigate = useNavigate();

	const hasPincode = localStorage.getItem('hasPincode');

	const [isPincodeModal, setIsPincodeModal] = useState(true);

	const [isComingSoonModalOpen, setComingSoonModalOpen] = useState(false);

	const handlePincodeSubmit = async (pincode) => {
		if (hasPincode === 'true') {
			const response = await verifyUserPincode(pincode);

			if (response.isSuccess) {
				setIsPincodeModal(false);
			} else return { error: response.error };
		} else {
			const response = await setUserPincode(pincode);

			if (!response.isSuccess) return { error: response.error };

			setIsPincodeModal(false);
			localStorage.setItem('hasPincode', true);
		}
	};

	return isPincodeModal ? (
		<PincodeModal
			isOpen={isPincodeModal}
			onClose={() => navigate('/')}
			onAction={async (pincode) => await handlePincodeSubmit(pincode)}
			buttonText={hasPincode === 'true' ? 'Verify' : 'Set'}
		/>
	) : (
		<div>
			<Header />
			<div className="flex flex-col items-center">
				<h1 className="font-bolder text-3xl mb-10 text-center xsm:text-[2rem]">Transfer Funds</h1>
				<div className="w-full flex jutify-center items-center flex-col">
					<div className="flex justify-center p-5">
						<div className="bg-primary rounded-2xl flex justify-center h-48 p-5 me-5">
							<button className="text-white text-2xl text-center" onClick={() => navigate('/transfer-request/member')}>
								To Paytora Member
							</button>
						</div>
						<div className="bg-primary rounded-2xl flex justify-center h-48 p-5 me-5">
							<button className="text-white text-2xl text-center" onClick={() => navigate('/transfer-request/iban')}>
								To Your IBAN Account
							</button>
						</div>
						{/* <div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 p-5">
							<button className="text-white text-2xl text-center" onClick={() => setComingSoonModalOpen(true)}>
								To A Business Merchant
							</button>
						</div> */}
					</div>
				</div>
			</div>

			<CustomModal
				isOpen={isComingSoonModalOpen}
				onClose={() => setComingSoonModalOpen(false)}
				title={'Will be available soon!'}
				subTitle={'This action will be available soon'}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setComingSoonModalOpen(false);
							}}
							text="Close"
							className="p-5 mt-5"
						/>
					</div>
				}
			/>
		</div>
	);
}
