import { Input } from '../../components/inputs/text-input';
import SelectInput from '../../components/inputs/select-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { useEffect, useState } from 'react';
import { getUserCardsAPI } from '../../services/cards';
import { getFeeByType } from '../../services/fees';
import { Header } from '../../components/navigation/header';
import Loader from '../utils/loader';
import { transferToCardFromIban } from '../../services/ibans';
import { useNavigate } from 'react-router-dom';

export default function IbanCardTransfer() {
	const navigate = useNavigate();

	const [cards, setCards] = useState([]);
	const [selectedCard, setSelectedCard] = useState(null);
	const [totalFee, setTotalFee] = useState(null);
	const [amount, setAmount] = useState();
	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const transferToCard = async () => {
		if (!amount || amount <= 0) return setErrMsg('Please enter a valid amount');

		if (!isLoading) {
			setIsLoading(true);

			const data = {
				amount,
				selectedCard,
			};

			const response = await transferToCardFromIban(data);

			if (!response.isSuccess) {
				setErrMsg(response.error ?? 'Error happened please contact support');
				setIsLoading(false);
				return;
			}
		}
		setIsLoading(false);

		navigate('/');
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			const availableCards = response.cards.filter((card) => card.status === 'A');
			setCards(availableCards);
			setSelectedCard(availableCards[0]._id);
			setIsLoading(false);
			const feesResponse = await getFeeByType({ type: 'card_top_up' });
			if (feesResponse.isSuccess) setTotalFee(feesResponse.data.cost);
		};

		fetchData();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header />
			<div className="flex flex-col items-center pb-20">
				<h1 className="font-bolder text-2xl xsm:text-[2rem] mb-5 text-center">Card Deposit From Your IBAN Account</h1>
				{cards.length === 0 ? (
					<p>You don't have cards yet, please apply for card</p>
				) : (
					<div className="flex justify-center">
						<div className="flex flex-col items-center p-5">
							<label className="font-bolder" htmlFor="amount">
								Select Amount To Transfer
							</label>
							<div className="flex justify-center">
								<Input
									name="amount"
									placeholder="Transfer Amount"
									type="number"
									value={amount}
									onChange={(value) => setAmount(value)}
									className={`bg-secondary border-2 border-black inline-block`}
									inputMode="numeric"
									pattern="[0-9]"
								/>
								<h1 className="mt-2 text-xl ms-2">€</h1>
							</div>

							<label className="font-bolder" htmlFor="cards">
								Select Card To Transfer
							</label>

							<SelectInput
								name="cards"
								onChange={setSelectedCard}
								options={cards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
								defaultValue={{ value: cards[0]._id, label: cards[0].pan + ' - ' + cards[0].balance + '€' }}
								className=""
							/>

							<div className="text-center mb-4 font-bolder">
								{totalFee && amount > 0 && (
									<p className="mb-1 mt-1 text-red-500">
										{parseInt(amount) - totalFee + '€ '}
										will be transfered to the selected card
									</p>
								)}
							</div>

							<p className="text-red-500 mb-5 font-bolder">{errMsg}</p>
							<PrimaryButton text="Transfer" onClick={transferToCard} className="p-10 mb-5" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
